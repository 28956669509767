import { Box, Container } from '@mui/material';
import soonImg from '../assets/Soon.png';
import whiteLogo from '../assets/White.png';
import React, { useState, useEffect } from 'react';


export const ComingSoonPage = () => {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if(width > 1024) {
        return (
            <main>
                <Box sx={ { backgroundColor: "#1915F0" } } display='flex' alignItems='center' justifyContent='center' flexDirection='column' padding='5rem' >
                        <Box mb='11rem'>
                            <img src={whiteLogo} alt='white' />
                        </Box>
                        <Box pb='14rem'>
                            <img src={soonImg} alt='Coming Soon' />
                        </Box>  
                </Box>
            </main>
        );
    } else {
        return (
            <main>
                 <Box sx={ { backgroundColor: "#1915F0" } } display='flex' alignItems='center' justifyContent='center' flexDirection='column' padding='3rem' paddingBottom='20rem'>
                    <Container>
                        <Box mb='11rem'>
                            <img src={whiteLogo} alt='white' height='100%' width='100%' />
                        </Box>
                        <Box pb='14rem'>
                            <img src={soonImg} alt='Coming Soon' height='100%' width='100%' />
                        </Box>
                    </Container>
                </Box>
            </main>
        );
    }

}