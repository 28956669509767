import { Box, Container, Typography } from '@mui/material';
import { Header } from '../components/Header';
import { Paragraph } from '../components/Paragraph';
import FooterComponent from '../components/Footer';

export const TermsOfService = () => {
    return (
        <main>
            <Box sx={{ backgroundColor: '#CDECE8'}}>
                <Header />
                <Container maxWidth='lg' style={{display: 'flex', alignItems: 'flex-start', marginTop: '2.5%'}}>
                    <Typography variant="h5" fontWeight={'bold'}>
                        Terms Of Service
                    </Typography>
                </Container>
                <Container maxWidth='lg' style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '1%'}}>
                    <Typography fontWeight={'bold'}>Last Updated: March 05, 2024</Typography>
                </Container>
                <Paragraph title='1. Introduction' title_size='h6' body='Welcome to ShowUp! These Terms of Service ("Terms") govern your access and use of our mobile application ("App"). By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, then you may not access or use the App.' />
                <Paragraph title='2. User Accounts and Registration' title_size='h6' body='1. Age Restriction: You must be 13 years of age or older to register for an account. By registering, you agree to these Terms and any future updates.' />
                <Paragraph body='3. Registration Information: During registration, you will be required to provide a username, email address, age, gender.' />
                <Paragraph body='4. Verification: We may, but are not obligated to, verify the age and identity of any user. We reserve the right to suspend or terminate your account if we have reason to believe you are under 13.' />
                <Paragraph body='5. Responsibility: You are solely responsible for maintaining the confidentiality of your account information, including your password. You are also responsible for all activity that occurs under your account.' />
                <Paragraph title='3. Group Creation and Restrictions' title_size='h6' body='1. Predefined Parameters: Users can create chat groups with pre-defined location and time restrictions. These parameters cannot be modified after creation.' />
                <Paragraph title='4. User Content and Conduct' title_size='h6' body='1. Content Responsibility: You are solely responsible for the content you share within chat groups. You agree to comply with all applicable laws and regulations when using the App.' />
                <Paragraph body='Prohibited Content: You agree not to share content that is:'/>
                <Container  maxWidth='lg' style={{ justifyContent: '', marginTop: '1%'}}>
                    <ul>
                        <li>
                            Illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive.
                        </li>
                        <li>
                            Infringes on the intellectual property rights of others.
                        </li>
                        <li>
                            Intended to impersonate another person or entity.
                        </li>
                        <li>
                            Intended to spam or solicit users.
                        </li>
                    </ul>
                </Container>
                <Paragraph body='2. Content Removal: We reserve the right, but are not obligated, to remove content that we deem to violate these Terms or is otherwise objectionable.' />
                <Paragraph title='5. Data Collection and Usage' title_size='h6' body='1. Collected Data: We collect the following data from users:' />
                <Container  maxWidth='lg' style={{ justifyContent: '', marginTop: '1%'}}>
                    <ul>
                        <li>Username</li>
                        <li>Age</li>
                        <li>Gender</li>
                        <li>Location (city and state level, not precise geolocation)</li>
                    </ul>       
                </Container>
                <Paragraph body='2. Data Usage: We use this data to:' />
                <Container  maxWidth='lg' style={{ justifyContent: '', marginTop: '1%'}}>
                    <ul>
                        <li>
                            Operate and improve the App.
                        </li>
                        <li>
                            Deliver targeted advertising within the App.
                        </li>
                    </ul>
                </Container>
                <Paragraph body='3. Data Sharing: We may share your data with third-party service providers who help us operate the App and deliver targeted advertising. These service providers are contractually obligated to keep your data confidential and secure. We will not share your data with any other third party without your consent, except as required by law.' />
                <Paragraph title='6. User Termination and Dispute Resolution' title_size='h6' body='1. Termination: We reserve the right to terminate your account for violating these Terms, engaging in illegal activity, or other reasons deemed necessary.' />
                <Paragraph body='2. Dispute Resolution: If you have any disputes regarding these Terms or your use of the App, you are encouraged to contact us first at [your designated email address]. We will attempt to resolve the dispute amicably. If we are unable to resolve the dispute, you agree to binding arbitration in accordance with the rules of the American Arbitration Association ("AAA").' />
                <Paragraph title='7. California Consumer Privacy Act (CCPA) Compliance' title_size='h6' body='We are committed to complying with the California Consumer Privacy Act ("CCPA"). You have the right to:' />
                <Container  maxWidth='lg' style={{ justifyContent: '', marginTop: '1%'}}>
                    <ul>
                        <li>
                            Know what personal information we collect, use, and disclose.
                        </li>
                        <li>
                            Request to delete your personal information.
                        </li>
                        <li>
                            Opt-out of the sale of your personal information.
                        </li>
                    </ul>
                </Container>
                <Paragraph body='For more information about your CCPA rights, please visit our Privacy Policy or contact us at contact@showup.chat.'/>
                <Paragraph title='8. Governing Law' title_size='h6' body='These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law provisions.'/>
                <Paragraph title='9. Entire Agreement' title_size='h6' body='These Terms constitute the entire agreement between you and us regarding your use of the App.' />
                <Paragraph title='10. Severability' title_size='h6' body='If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.' />
                <Paragraph title='11. Changes to Terms' title_size='h6' body='We reserve the right to update these Terms at any time by posting the revised Terms on the App. You are encouraged to review the Terms periodically for any updates. You agree to be bound by any revisions to the Terms by continuing to access or use the App after the revised Terms are posted.' />
                <Paragraph title='12. Contact Us' title_size='h6' body='If you have any questions regarding these Terms, please contact us at contact@showup.chat.' />
                <FooterComponent wd='lg' />
            </Box>
        </main>
    );
}