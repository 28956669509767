import { Container, Typography } from "@mui/material";


export const Paragraph = (props) => {
    const body = props.body;
    const title = props.title;
    const title_size = props.title_size;

    return (
        <Container  maxWidth='lg' style={{ justifyContent: '', marginTop: '1%'}}>
            <Typography variant={title_size} fontWeight={'bold'} marginBottom={''} >{title}</Typography>
            <Typography marginTop={'1%'}>{body}</Typography>
        </Container>
    );
}