import { Box, Container, Typography } from '@mui/material';
import { Header } from '../components/Header';
import { Paragraph } from '../components/Paragraph';
import FooterComponent from '../components/Footer';

export const PrivacyPolicy = () => {
    return  (
        <main>
            <Box sx={{ backgroundColor: '#CDECE8'}}>
                <Header />
                <Container maxWidth='lg' style={{display: 'flex', alignItems: 'flex-start', marginTop: '2.5%'}}>
                    <Typography variant="h5" fontWeight={'bold'}>
                        Privacy Policy
                    </Typography>
                </Container>
                <Container maxWidth='lg' style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '1%'}}>
                    <Typography fontWeight={'bold'}>Last Updated: March 05, 2024</Typography>
                </Container>
                <Paragraph title='1. Introduction' title_size='h6' body='This Privacy Policy explains how ShowUp ("we," "us," or "our") collects, uses, and discloses your information when you use our mobile application ("App"). We are committed to protecting and respecting your privacy in accordance with the General Data Protection Regulation ("GDPR") and other applicable data protection laws.' />
                <Paragraph title='2. Information We Collect and Legal Basis for Processing' title_size='h6' body='We collect the following information from users:' />
                <Paragraph body='1. Registration Information: When you register for an account, we collect your username, email address, age, gender. The legal basis for processing this data is your consent given during registration.' />
                <Paragraph body='2. Usage Data: We may collect information about your activity on the App, such as the groups you join, the messages you send, your browsing activity, and your location (city and state level for analytical purposes, and the general vicinity based on IP address for group creation purposes). This information is used to improve the App, personalize your experience, deliver targeted advertising based on your legitimate interest, and facilitate the creation of location-based groups. We will only process your data for these purposes with your consent, which you can withdraw at any time.' />
                <Paragraph title='3. How We Use Your Information' title_size='h6' body='We use the information we collect for the following purposes:' />
                <Paragraph body='To operate and improve the App: We use your information to operate the App, personalize your experience, deliver targeted advertising based on your legitimate interest, and facilitate the creation of location-based groups.' />
                <Paragraph body='To communicate with you: We may use your email address to send you important information about the App, such as service updates and changes to our terms of service. Your consent is the legal basis for this communication.' />
                <Paragraph body='To comply with the law: We may disclose your information to law enforcement or other government officials in response to a subpoena, warrant, or other legal process.'/>
                <Paragraph title='4. Sharing Your Information' title_size='h6' body='We may share your information with the following third-party service providers under appropriate contractual agreements that ensure adequate data protection safeguards:' />
                <Paragraph body='1. Analytics providers: We use third-party analytics providers to help us analyze how users use the App. These providers are chosen based on their robust data security measures and compliance with GDPR.'/>
                <Paragraph body='2. Advertising providers: We use third-party advertising providers to serve targeted advertising on the App. These providers must comply with GDPR and only process your data based on your consent, where applicable.' />
                <Paragraph title='5. Your Rights Under GDPR' title_size='h6' body='You have the following rights under the GDPR:' />
                <Paragraph body='1. Right to access your personal data: You can request access to the personal data we hold about you.' />
                <Paragraph body='2. Right to rectification: You can request that we correct any inaccurate or incomplete personal data we hold about you.' />
                <Paragraph body='3. Right to erasure (right to be forgotten): You can request that we delete your personal data, subject to certain legal exceptions.' />
                <Paragraph body='4. Right to restriction of processing: You can request that we restrict the way we process your personal data in certain circumstances.' />
                <Paragraph body='5. Right to data portability: You can request that we provide you with your personal data in a structured, commonly used, and machine-readable format and transfer it to another controller.' />
                <Paragraph body='6. Right to object: You can object to the processing of your personal data, including for marketing purposes.' />
                <Paragraph title='6. Data Transfers' title_size='h6' body='If we transfer your data outside the European Economic Area (EEA), we will ensure that the transfer is subject to appropriate safeguards, such as standard contractual clauses approved by the European Commission or other mechanisms permitted by GDPR.' />
                <Paragraph title='7. Data Retention' title_size='h6' body='We will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, or as required by law. We will then delete your personal data in a secure manner.' />
                <Paragraph title='8. Data Security' title_size='h6' body='We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk of processing your personal data.' />
                <Paragraph title="9. Children's Privacy" title_size='h6' body='We do not intentionally collect personal information from children under the age of 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us. If we learn that we have collected personal information from a child under 13, we will delete that information from our systems.' />
                <Paragraph title='10. Your California Privacy Rights (CCPA)' title_size='h6' body='If you are a California resident, you have additional rights under the California Consumer Privacy Act ("CCPA"). For more information about your CCPA rights, please visit our [link to CCPA information page] or contact us at contact@showup.chat.' />
                <Paragraph title='11. Changes to this Privacy Policy' title_size='h6' body='We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy' />
                <FooterComponent wd='lg' />
            </Box>            
        </main>
    );
}