import React from "react";
import { Box, Typography } from "@mui/material";

const TextComponent = (props) => {
  const path_to_image = props.img;
  const content = props.content;
  const headline = props.headline;
  const apple = props.apple;
  const google = props.google;
  const headlineFontSize = props.headlineFontSize;
  const textFontSize = props.textFontSize;
  const imgHeight = props.imgHeight;
  const imgWidth = props.imgWidth;
  let textColor = props.textColor;
  if (textColor == null) {
    textColor = '#00000'
  }


  return (
    <Box display="flex" flexDirection="column">
      <Box style={{ paddingBottom: "20px" }}>
        {
          imgHeight && imgWidth ? <img src={path_to_image} alt="test" height={imgHeight} width={imgWidth}/> : <img src={path_to_image} alt="test"/>
        }
      </Box>
      <Box style={{ marginBottom: "20px" }}>
        <Typography fontWeight="bold" fontSize={headlineFontSize} style={{color: textColor}}>
          {headline}
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={textFontSize} style={{color: textColor}} >{content}</Typography>
      </Box>
      {google && (
        <Box style={{ marginTop: "35px" }}>
          <a href="https://google.com">
            <img src={google} alt="google" />
          </a>
        </Box>
      )}
      {apple && (
        <Box style={{ marginTop: "20px" }}>
          <a href="https://youtube.com">
            <img src={apple} alt="apple" />
          </a>
        </Box>
      )}
    </Box>
  );
};

export default TextComponent;
