import { Container, Box} from "@mui/material";
import TextComponent from "./TextComponent";
import apple from '../assets/5a902db97f96951c829228742.png';
import google from '../assets/google-play-badge2.png';
import myImage from '../assets/app_icon.png';
import PhoneComponent from "./PhoneComponent";
import button1 from '../assets/Buttons.png';
import phone4 from '../assets/phone5.png';
import phone2 from '../assets/img2.png';
import phone3 from '../assets/img3.png';
import button2 from '../assets/Buttons2.png';
import button3 from '../assets/capybara.png';



const MobileUI = () => {
    return (
      <Box>
        <Box style={{backgroundColor: '#CDECE8', paddingTop: '15%', paddingBottom: '10%', paddingLeft: '5%', paddingRight: '5%'}}>
          <Container maxWidth='sm'>
            <TextComponent img={myImage} imgHeight='100%' imgWidth='100%' content="Earn your right to be part of the community by periodically visiting the location at which the chat group was created at. You don’t show up, you get kicked out." headline='Say what now?' apple={apple} google={google} headlineFontSize='22px' textFontSize='18px'/>
          </Container> 
        </Box>
        <Box style={{backgroundColor: '#F3C522', paddingTop: '15%', paddingLeft: '5%', paddingRight: '5%'}}>
          <Container maxWidth='sm'>
            <PhoneComponent imgPath={phone2} height={'100%'} width={'100%'} />    
          </Container> 
        </Box>
        <Box style={{backgroundColor: '#00BEA6', paddingTop: '10%', paddingBottom: '10%', paddingLeft: '5%', paddingRight: '5%'}}>
          <Container maxWidth='sm'>
          <TextComponent img={button1} content="Create hyper local group chats that reward real life engagement and effort. Escape the online echo chambers and get acquainted with your local community." headline='Convos' headlineFontSize='22px' textFontSize='18px'/>
          </Container> 
        </Box>
        <Box style={{backgroundColor: '#FF7768', paddingBottom: '15%', paddingLeft: '5%', paddingRight: '5%'}}>
          <Container maxWidth='sm'>
            <PhoneComponent imgPath={phone3} height={'100%'} width={'100%'} />    
          </Container> 
        </Box>
        <Box style={{backgroundColor: '#1D1D1F', paddingTop: '10%', paddingBottom: '10%', paddingLeft: '5%', paddingRight: '5%'}}>
          <Container maxWidth='sm'>
            <TextComponent img={button2} content="Leave tips and notes in real world locations that can only be viewed by the people wandering in the area. Create opportunities for real world exploration and discovery. Try fingers, but hole." headline='Stamps' headlineFontSize='22px' textFontSize='18px' textColor='#ffffff'/>
          </Container> 
        </Box>
        <Box style={{backgroundColor: '#1740F1', paddingTop: '15%', paddingLeft: '5%', paddingRight: '5%'}}>
          <Container maxWidth='sm'>
            <PhoneComponent imgPath={phone4} height={'100%'} width={'100%'} />    
          </Container> 
        </Box>
        <Box style={{backgroundColor: '#46AF64', paddingTop: '10%', paddingBottom: '10%', paddingLeft: '5%', paddingRight: '5%'}}>
          <Container maxWidth='sm'>
            <TextComponent img={button3} content="We believe a community in which the members spend more real world time with each other is stronger and more valuable than online communities in which the members can just pay for their access. Anyone can talk a big game online, but do they have the guts to back it up by showing up when they are needed? " headline='Show Up' headlineFontSize='22px' textFontSize='18px'/>
            {/* <Typography fontSize={'18px'} style={{marginTop: '20px'}}>PS: All of your data stays with you.</Typography> */}
          </Container> 
        </Box>
        {/* <FooterComponent padding_size='5%' /> */}
      </Box>
    );
}

export default MobileUI;