import { Box, Container } from "@mui/material";
import TextComponent from "./TextComponent";
import PhoneComponent from "./PhoneComponent";
import myImage from '../assets/app_icon.png';
import phone1 from '../assets/Map_zoom_in.png';
import apple from '../assets/5a902db97f96951c829228742.png';
import google from '../assets/google-play-badge2.png';
import button1 from '../assets/Buttons.png';
import phone2 from '../assets/img2.png';
import phone3 from '../assets/img3.png';
import button2 from '../assets/Buttons2.png';
import phone4 from '../assets/phone5.png';
import button3 from '../assets/capybara.png';


const DesktopUI = () => {

    return (
        <Box>
            <Box sx={{ backgroundColor: '#CDECE8'}}>
                <Container maxWidth='md' style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                    <Box style={{  paddingRight: '15%', marginTop: '10.5%', display: 'inline-block' }}>
                        <TextComponent img={myImage} content="Earn your right to be part of the community by periodically visiting the location at which the chat group was created at. You don’t show up, you get kicked out." headline='Say what now?' apple={apple} google={google} headlineFontSize='31px' textFontSize='20px' />
                    </Box>
                    <Box style={{ marginTop: '10.5%', display: 'inline-block'}}>
                        <PhoneComponent imgPath={phone1} height={'575px'} width={'370px'} />    
                    </Box>    
                </Container> 
            </Box>
            <Box sx={{ backgroundColor: '#F3C522'}}>
                <Container maxWidth='md' style={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box style={{ marginRight: '14%', marginTop: '10.5%', display: 'inline-block'}}>
                        <PhoneComponent imgPath={phone2} height={'590px'} width={'370px'} />    
                    </Box>
                    <Box style={{  marginTop: '10.5%'}}>
                        <TextComponent img={button1} content="Create hyper local group chats that reward real life engagement and effort. Escape the online echo chambers and get acquainted with your local community." headline='Convos' headlineFontSize='31px' textFontSize='20px'/>
                    </Box>
                </Container> 
            </Box>
            <Box sx={{ backgroundColor: '#00BEA6'}}>
                <Container maxWidth='md' style={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box style={{  marginTop: '10.5%', paddingRight: '15%'}}>
                        <TextComponent img={button2} content="Leave tips and notes in real world locations that can only be viewed by the people wandering in the area. Create opportunities for real world exploration and discovery. Try fingers, but hole." headline='Stamps' headlineFontSize='31px' textFontSize='20px'/>
                    </Box>
                    <Box style={{ marginBottom: '10.5%', display: 'inline-block'}}>
                        <PhoneComponent imgPath={phone3} height={'575px'} width={'370px'} />    
                    </Box>
                </Container> 
            </Box>    
            <Box sx={{ backgroundColor: '#46AF64'}}>
                <Container maxWidth='md' style={{display: 'flex', alignItems: 'flex-start'}}>
                    <Box style={{ marginRight: '14%', marginTop: '10.5%', display: 'inline-block'}}>
                        <PhoneComponent imgPath={phone4} height={'590px'} width={'370px'} />    
                    </Box>
                    <Box style={{  marginTop: '10.5%'}}>
                        <TextComponent img={button3} content="We believe a community in which the members spend more real world time with each other is stronger and more valuable than online communities in which the members can just pay for their access. Anyone can talk a big game online, but do they have the guts to back it up by showing up when they are needed?" headline='Show Up' headlineFontSize='31px' textFontSize='20px'/>
                        {/* <Typography fontSize={'20px'} style={{marginTop: '20px'}}>PS: All of your data stays with you.</Typography> */}
                    </Box>
                </Container> 
            </Box>
        </Box>


    );
}

export default DesktopUI;