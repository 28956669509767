import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from './pages/Homepage';
import { ManifestoPage } from './pages/Manifesto';
import { PrivacyPolicy } from './pages/Privacy';
import { TermsOfService } from './pages/Terms';
import { ComingSoonPage } from './pages/ComingSoon';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='home' element={<HomePage/>} />
        <Route path='manifesto' element={ <ManifestoPage/> }/>
        <Route path='privacy' element={ <PrivacyPolicy /> } />
        <Route path='terms' element={ <TermsOfService/> }  />
        <Route path='/' element={ <ComingSoonPage /> } />
      </Routes>
    </BrowserRouter>
  );


}

export default App;
