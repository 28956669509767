import { Facebook, Instagram, X } from "@mui/icons-material";
import { Box, Container, Typography, Link, IconButton } from "@mui/material";


const FooterComponent = (props) => {
    let padding_size = props.padding_size;
    let width = props.wd;
    if (padding_size == null) {
        padding_size = '0'
    }


    return (
        <Box style={{backgroundColor: '#CDECE8'}}>
            <Container maxWidth={width} style={{ display: 'flex', justifyContent: 'space-between', padding: '1.5rem' }}>
                <Link href='/manifesto' underline="hover" color='inherit'>
                    <Typography>Manifesto</Typography>
                </Link>
                <Link href='/privacy' underline="hover" color='inherit'>
                    <Typography>Privacy Policy</Typography>
                </Link>
                <Link href='/terms' underline="hover" color='inherit'>
                    <Typography>Terms of Service</Typography>
                </Link>
            </Container>  
            <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row' pb='1rem'>
                <IconButton>
                    <Facebook/>
                </IconButton>
                <IconButton>
                    <Instagram />
                </IconButton>
                <IconButton>
                    <X />
                </IconButton>
            </Box> 
        </Box>

    );
}

export default FooterComponent;