import DesktopUI from '../components/DesktopUI';
import FooterComponent from '../components/Footer';
import MobileUI from '../components/MobileUI';
import React, { useState, useEffect } from 'react';


export const HomePage = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    if (width > 1024) {
      return (
        <html>
          <main>
            <DesktopUI/>
          </main>
          <FooterComponent wd='md'/>
        </html>
      );
    } else {
        return (
        <html>
          <main>
            <MobileUI />
          </main>
          <FooterComponent/>
        </html>
  
      );  
    }
}