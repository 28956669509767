import { Box } from "@mui/material";

const PhoneComponent = (props) => {

    const imgPath = props.imgPath;
    const width = props.width
    const height = props.height

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
            <img src={imgPath} alt="Phone Pic" height={height} width={width}/>
        </Box>
    );
}

export default PhoneComponent;