import { Box, Button, Container } from "@mui/material";
import app_icon from '../assets/cbwb.png';
import { Link } from 'react-router-dom';


export const Header = () => {
    return (
        <Container maxWidth='lg' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            <Box marginTop={'3%'}>
                <Link to={'/'}>
                    <img src={app_icon} alt="app_icon" height={'65px'} width={'65px'}/>
                </Link>
            </Box>
            <Box marginTop={'5%'}>
                <Link to={'/'} >
                    <Button variant="contained" style={{background: '#000000', color: '#ffffff', textTransform: 'none', borderRadius: '100px', paddingLeft: '25px', paddingRight: '25px'}}>Get The App</Button>
                </Link>
            </Box>
        </Container>
    )
}