import { Box, Container, Typography } from "@mui/material";
import { Header } from "../components/Header";
import { Paragraph } from "../components/Paragraph";
import FooterComponent from "../components/Footer";

export const ManifestoPage = () => {
    return (
        <main>
            <Box sx={{ backgroundColor: '#CDECE8'}}>
                <Header />
                <Container maxWidth='lg' style={{display: 'flex', alignItems: 'flex-start', marginTop: '2.5%'}}>
                    <Typography variant="h5" fontWeight={'bold'}>
                        Manifesto
                    </Typography>
                </Container>
                <Container maxWidth='lg' style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginTop: '1%'}}>
                    <Typography fontWeight={'bold'}>Last Updated: February 21, 2024</Typography>
                </Container>
                <Paragraph body='ShowUp is an app in which you can create chat groups(Convos) or leave notes(Stamps) in real world locations.' title=' What is ShowUp?' title_size='h6' />
                <Paragraph body="It's pretty much a chat app. The only thing is, you have to periodically visit the location at which the chat group was created in order to keep your access to the chat group. If you don't visit for long enough, you get automatically kicked out. Simple as that." title='1. Convos' title_size='h7' />
                <Paragraph body="These are location specific tips/notes that users can leave for other users to discover. You can’t respond to these messages, and if the stamp doesn’t receive upvotes at a given interval, the stamp dies and disappears. If the stamp receives downvotes, it will die faster. If you have played Elden Ring, the inspiration for Stamps came from the in-game messaging feature from that game. “Try fingers, but hole!” " title="2. Stamps" title_size="h7" />
                <Paragraph title="Why did we make this app?" title_size="h6"/>
                <Paragraph title='1. The 90s' title_size='h7' body="The 90s, a decade etched in nostalgia, holds a special charm that many of us, as 90s kids, can't help but reminisce about. Amidst the rose-tinted memories, it's worth acknowledging the unique experiences that made the 90s truly distinctive." />
                <Paragraph body="Imagine the thrill of calling your crush on a landline, only to be greeted by the nerve-wracking voice of her dad. The handwritten notes expressing your feelings, passed discreetly to your crush, embodying a simplicity now lost in the digital age. The anticipation of meeting friends at a pre-designated spot, navigating terrain with the hope that everyone would show up - a testament to the commitment of a pre-digital era." />
                <Paragraph body="The internet back then resembled an uncharted jungle, a space where every click led to unexpected discoveries. It was a realm driven more by creativity and spontaneity than targeted ads and data mining. A time when the online world felt like a playground where creators added content just for the joy of it, without the overarching goal of maximizing profits." />
                <Paragraph body="The magic of the 90s lies in its paradoxical blend of rapid advancement, growth, and optimism alongside tangible limitations. Scheduled plans meant showing up on time, and the absence of casual rescheduling made interactions more genuine. The physical realm, with its seamless connectivity, trumped the digital, fostering outdoor play, genuine friendships, and organic social groups formed based on physical proximity." />
                <Paragraph body="Friendships in the 90s had a natural rhythm - new faces entering the neighborhood brought excitement, while saying goodbye to old friends carried a sense of real loss. The modern era, with its virtual connections, lacks the organic flow and natural transitions of friendships formed in a pre-digital world." />
                <Paragraph body="Reflecting on this, our app strives to recreate the essence of 90s experiences by imposing heavy location and time restrictions. In embracing the challenges of that era, we believe in the philosophy of 'no pain, no gain,' aiming to reintroduce the authenticity, commitment, and genuine connections that defined the 90s." />
                <Paragraph title="2. Polarization of social media" title_size='h7' body="One glaring issue prevalent in contemporary social networks and media platforms is their marked disregard for the significance of location in fostering meaningful connections. In the digital age, we find ourselves developing profound relationships with individuals whom we may never have the chance to meet in person throughout our lifetimes. While the emergence of niche online communities has brought about positive aspects, it has also ushered in a notable societal trend – the gradual polarization of opinions towards the extremes." />
                <Paragraph body="As we immerse ourselves in these online ecosystems, the risk of losing touch with the nuances of diverse perspectives becomes apparent. Physical distance, once a limiting factor, is now overshadowed by the echo chambers that echo and reinforce our existing beliefs. The ramifications of this trend extend beyond the digital realm, contributing to the widening ideological gaps that manifest in various facets of society." />
                <Paragraph body="Addressing this challenge requires a reevaluation of the role of location in our digital interactions. By incorporating a nuanced understanding of physical proximity, social networks can potentially mitigate the polarizing effects observed in online communities. By balancing the advantages of virtual connectivity with a renewed emphasis on the importance of local interactions, we aspire that our app can contribute, even if modestly, to counteracting this polarization." />
                <Paragraph title='3. The case for the death of content & friendships' title_size='h7' body='The transient nature of life is what gives it value. Ok, that was way too deep. The point is, things need to end on a timely basis in order for the experience to be fulfilling and enjoyable. Especially with the onset of AI & the proliferation of bots, the internet is becoming a crowded dumpster chalk full of fake bot accounts posting AI generated content. Every new account and every new AI post diminishes the value of authentic human interactions on social media platforms. What is the point of being a member in a group with 20k members, with the only incentive to post something being to advertise. That is why our app aims to kill off as much useless content, memberships as possible.' />
                <Paragraph title="Mission, vision and all that stuff." title_size="h6" body="Our app doesn't aspire to reshape the world, tackle climate change, address inequality, or grapple with the complexities of the AI singularity. We're not promising a life-altering experience or claiming to make your life extraordinary. Instead, our modest ambition is for the app to serve as a practical tool, focusing on the cultivation of robust local communities. Our primary hope is that it becomes a facilitator for building strong connections within neighborhoods. Simultaneously, we aim for the app to generate sufficient revenue, allowing our team to lead comfortable lives—ideally upper middle-class Swiss." />
                <Paragraph title="How are we going to develop the app in the future?" title_size="h6" body="In an era marked by relentless and rapid change, the constant demand to keep pace has become overwhelming for everyone, including us. The driving force behind creating this app stems from our earnest desire to achieve a level of success that liberates us from the relentless pace and intensity of our current endeavors." />
                <Paragraph body = "What features are users requesting the most? How can we integrate AI into this? Should we develop a dedicated marketing API for businesses? Where can we hire the best and brightest developers? What food will we serve in the employee canteen? These are exactly the kinds of questions we don’t want to think about. " />
                <Paragraph body="Embracing the philosophy of 'less means more,' we reject the race towards maximum efficiency and productivity. Our approach stands in stark contrast to the prevalent industry trend of rapid iterations, A/B testing, and a constant influx of new designs, buttons, and nomenclature bombarding users. Yes, we will leave money on the table" />
                <Paragraph title="What are we going to do with user data?" title_size="h6" body="We're not holding on to stuff forever – storage costs are through the roof! Analyzing data for targeted marketing and diving into legal hoops? Way too much hassle for us. If a Convo or Stamp kicks the bucket, well, it is what it is. No tears, no drama." />
                <Paragraph body="Imagine you start this epic chat group with your buddies, filled with a decade's worth of awesome talks. Then, one by one, your pals ghost the place, and the Convo is left in the digital dust with zero active members. Poof! Gone forever. No backups here. It's a bit sad, but think of it as a nudge to find the next great chat spot. Life's too short to dwell on old, inactive Convos! " />
                <Paragraph body="We’re not going to look into the contents of your chat group (unless it's flagged and reported as something illegal, in which case we are required by law to moderate the content). The only user data we will hold onto and use for the purposes of ad targeting are name, e-mail, phone number,DOB, and location(from when you used the app). " />
                <Paragraph title="Who we are." title_size="h6" body="We are a team of millennials, jaded by pretty much everything that us millennials are jaded by these days. Listing them all would be too long, but you get the point. " />
                <Paragraph title="Did we make this app to get rich?" body="Yes." title_size="h6" />
                <Paragraph body=' And also, the app needs to sustain its own growth. If it doesn’t, now only are we not making any money, but we will be actively losing money. The cost combined with the irreparable loss of our daily beauty sleep, and we cannot afford not to make money.' /> 
                <Paragraph body='However, we have no intention of turning the app into an all you can get cash grab by mining and selling data, inundating the users with endless ads, or selling all kinds of skins/graphics/perks/energy/status/bling/coins etc. Our object is to keep the monetization as simple and straightforward as possible, so that it doesn’t take away from the general user experience. ' />
                <Paragraph body="We will have 1 google ad banner on the home feed and 1 more of our own in app banner per cell. So 2 banners and that's it. As long as these 2 banners can keep the lights on, we will be pretty happy with that. " />
                <Paragraph title="What's with the Capybara?" title_size="h6" body="Who doesn’t love Capybaras." />
                <FooterComponent wd='lg'/>
            </Box>
        </main>

    );
} 